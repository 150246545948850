export const DEFAULT_MODAL_DIMENSIONS = { height: 380, width: 580 };
export const PREVIEW_MODAL_DIMENSIONS = { height: 600, width: 600 };

export const PRICING_PLAN_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';

export const PRICING_PLAN_SECTION_ID = 'membership_plan_picker_tpa';

export const RTL_LANGUAGES = ['ar', 'fa', 'he'];

export const BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';

export const BOOKINGS_CHECKOUT_SECTION_ID = 'book_checkout';

export const BOOKINGS_SCHEDULER_SECTION_ID = 'scheduler';

export const BOOKINGS_LIST_SECTION_ID = 'bookings_list';

export const BOOKINGS_SERVICE_PAGE_SECTION_ID = 'Booking Service Page';

export const BOOKINGS_CALENDAR_SECTION_ID = 'Booking Calendar';

export const BOOKINGS_FORM_SECTION_ID = 'Booking Form';

export const SANTA_MEMBERS_APP_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

export const BOOKINGS_MEMBERS_AREA_WIDGET_ID =
  '14edb332-fdb9-2fe6-0fd1-e6293322b83b';

export const MEMBERS_AREA_SECTION_ID = 'bookings_member_area';

export const ECOM_APP_DEF_ID = '1380b703-ce81-ff05-f115-39571d94dfcd';

export const ECOM_CART_SECTION_ID = 'shopping_cart';
