import {
  ServiceOptionType,
  ServiceOptionsAndVariants,
} from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { DynamicPriceInfo as DynamicPriceInfoStorage } from '@wix/bookings-uou-types';
import { Service } from '../../mappers/service.mapper';
import { FormApi } from '../../../api/FormApi';
import {
  DynamicPriceInfo,
  SelectedPreferences,
} from '../../../types/dynamicPrice';
import {
  selectedVariantsAndServiceOptionsToCustomOptions,
  isPreferenceOption,
} from '../../mappers/dynamic-price.mapper';
import { IFlowApiAdapter } from '../../flow-api-adapter/types';

export interface InitiateDynamicPriceState {
  formApi: FormApi;
  service: Service;
  dynamicPricePreSelection?: DynamicPriceInfoStorage;
  wixSdkAdapter: IFlowApiAdapter;
  dateRegionalSettingsLocale: string;
  serviceOptionsAndVariants?: ServiceOptionsAndVariants;
}

export const initiateDynamicPriceState = ({
  service,
  dynamicPricePreSelection,
  dateRegionalSettingsLocale,
  serviceOptionsAndVariants,
}: InitiateDynamicPriceState): DynamicPriceInfo | undefined => {
  if (
    (!serviceOptionsAndVariants && !dynamicPricePreSelection) ||
    !service?.payment?.paymentDetails?.isVariedPricing
  ) {
    return;
  }

  const result: DynamicPriceInfo = {};

  if (serviceOptionsAndVariants) {
    const selectedPreferences = createSelectedPreferences(
      service,
      serviceOptionsAndVariants,
    );

    if (selectedPreferences.length) {
      result.selectedPreferences = selectedPreferences;
    }

    result.customOptions = selectedVariantsAndServiceOptionsToCustomOptions({
      selectedVariants: dynamicPricePreSelection?.selectedVariants,
      serviceOptions: serviceOptionsAndVariants?.options,
      serviceVariants: serviceOptionsAndVariants?.variants,
      dateRegionalSettingsLocale,
    });

    result.isDynamicPreferenceType = isPreferenceOption(
      serviceOptionsAndVariants?.options!,
    );
  }

  result.selectedVariants = dynamicPricePreSelection?.selectedVariants;
  result.nonEditable = dynamicPricePreSelection?.nonEditable;
  result.defaultPrice = {
    amount: service.payment.paymentDetails.price?.toString(),
    currency: service.payment.paymentDetails.currency,
  };
  result.serviceOptionsAndVariants = serviceOptionsAndVariants;

  return result;
};

const createSelectedPreferences = (
  service: Service,
  serviceOptionsAndVariants?: ServiceOptionsAndVariants,
): SelectedPreferences[] => {
  const selectedPreferences: SelectedPreferences[] = [];

  serviceOptionsAndVariants?.options?.values?.forEach((option) => {
    switch (option.type) {
      case ServiceOptionType.STAFF_MEMBER: {
        return selectedPreferences.push({
          type: ServiceOptionType.STAFF_MEMBER,
          id: service.staffMembers?.[0]?.id,
        });
      }
    }
  });

  return selectedPreferences;
};
